import React, { useEffect, useState } from 'react';

interface CAT20Item {
  icon: string;
  holder_count: string;
  tick: string;
  price: string;
}
let usdPriceForFB: number | null = null; // Variable to store the USD price of FB

// Fetch USD price for FB only once and store it
async function fetchUsdPriceForFB() {
  if (usdPriceForFB !== null) {
    // If we already have the price, return it to avoid re-fetching
    return usdPriceForFB;
  }

  const url = "https://api.swap.dotwallet.com/brc20swap/stat/tick_price";
  const payload = {
    coin_type: "fractal",
    tick: "FB", // Fetching the USD price of FB
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    const data = await response.json();
    usdPriceForFB = data.data.usd_price; // Save the price in the variable

    console.log("Fetched USD Price for FB:", usdPriceForFB);
    return usdPriceForFB; // Return the price
  } catch (error) {
    console.error("Error fetching USD price for FB:", error);
    return null; // Handle error
  }
}




async function fetchCat20Tick(tick: string) {
  const start_time = Math.floor(Date.now() / 1000);

// Get Unix time for 24 hours ago
const end_time = start_time - 1400;

  const url = "https://api.swap.dotwallet.com/brc20swap/stat/price_graph2";
  const payload = {
    coin_type: "cat20",
    tick: tick,
    end_time: start_time,
    start_time: end_time,
    crypto_type: "fractal",
    type: "1D"
  };
console.log(payload);
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    const data = await response.json();
    const newdata = data.data.items.reverse();
    console.log(newdata);
    return newdata[0].value; // Assuming this is the price
  } catch (error) {
    console.error("Error fetching Cat20 tick data:", error);
    return null;
  }
}

// Delay function to add a throttle
const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

async function fetchTicks() {
 
  const url = "https://api.swap.dotwallet.com/brc20swap/search_ticks";
  const payload = {
    address: "",
    coin_type: "cat20",
    keyword: "",
    page: 1,
    page_size: 30,
    tick1: "FB",
    usetype: "gettick2",
  };

  try {
    const usdPriceForFB = await fetchUsdPriceForFB(); // Fetch USD price of FB (only fetches once)

    const response = await fetch(url, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    const data = await response.json();

    // Sort the results by holder_count in descending order
    const sortedData = data.data.items.sort((a: any, b: any) => b.holder_count - a.holder_count);

    // Fetch price for each token (tick) and add it to the result, with a delay between requests
    const enhancedData = [];

    for (const item of sortedData) {
      const priceInFB  = await fetchCat20Tick(item.tick);
      const price = priceInFB && usdPriceForFB ? (priceInFB * usdPriceForFB).toFixed(6) : null;

      enhancedData.push({ ...item, price });

      // Add a delay of 500ms (for example) between each request
     
    }

    console.log("Enhanced Data with Prices:", enhancedData);
    return enhancedData;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
}

const CAT20Page = ({ connected }: { connected: boolean }) => {
  const [cat20list, setCAT20List] = useState<CAT20Item[]>([]);
  const [loading, setLoading] = useState(true);

  // To prevent multiple fetches, add a flag that ensures the data is fetched only once
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    if (connected && !dataFetched) {
      setLoading(true);
      fetchTicks()
        .then((list) => {
          if (list) {
            setCAT20List(list); // Only set the state if the list is not null
            setDataFetched(true); // Mark data as fetched
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching CAT20 list:', error);
          setLoading(false);
        });
    }
  }, [connected, dataFetched]);

  return (
    <div className="flex flex-col items-center p-4 sm:p-8">
      <div className="block-info w-full p-4 sm:p-8 bg-black bg-opacity-80 rounded-lg shadow-lg">
        <div className="text-left mb-4 sm:mb-6">
          <div className="font-bold text-xl sm:text-2xl text-white">CAT20</div>

          {loading ? (
            <div className="text-white">Loading CAT20 data...</div>
          ) : cat20list.length > 0 ? (
            <table className="min-w-full table-auto bg-gradient-to-r from-gray-1000 to-gray-700 rounded-lg overflow-hidden">
              <thead>
                <tr className="text-left text-white">
                  <th className="p-2 sm:p-4">Ticker</th>
                  <th className="p-2 sm:p-4">Holders Count</th>
                  <th className="p-2 sm:p-4">Price</th>
                </tr>
              </thead>
              <tbody>
                {cat20list.map((item, index) => (
                  <tr
                    key={index}
                    className={`${
                      index % 2 === 0
                        ? 'bg-black bg-opacity-40'
                        : 'bg-black bg-opacity-20'
                    } hover:bg-gray-600 transition-colors`}
                  >
                    <td className="p-1 sm:p-2 text-white">{item.icon}</td>
                    <td className="p-1 sm:p-2 text-white">{item.holder_count}</td>
                    <td className="p-1 sm:p-2 text-white">${item.price || "N/A"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="text-white">No CAT20 data available</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CAT20Page;
