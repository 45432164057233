import React, { useEffect, useState } from 'react';
import { fetchBRC20List } from '../../components/fractalBRC'; // Adjusted import for the BRC20 fetching function

interface BRC20Item {
  ticker: string;
  holdersCount: string;
  inscriptionId: string;
}

const FractalBRC20Page = ({ connected }: { connected: boolean }) => {
  const [brc20list, setBrc20List] = useState<BRC20Item[]>([]);
  const [loading, setLoading] = useState(true);

  // Fetch BRC20 list when connected
  useEffect(() => {
    if (connected) {
      setLoading(true);
      fetchBRC20List()
        .then((list) => {
          setBrc20List(list);
          setLoading(false); // Stop loading after fetching
        })
        .catch((error) => {
          console.error('Error fetching BRC20 list:', error);
          setLoading(false); // Stop loading on error
        });
    }
  }, [connected]);

  // Helper function to shorten inscription IDs
  const shortenInscriptionId = (id: string) => {
    return id.length > 12 ? `${id.slice(0, 6)}...${id.slice(-6)}` : id;
  };

  return (
    <div className="flex flex-col items-center p-4 sm:p-8">
      <div className="block-info w-full p-4 sm:p-8 bg-black bg-opacity-80 rounded-lg shadow-lg">
        <div className="text-left mb-4 sm:mb-6">
          <div className="font-bold text-xl sm:text-2xl text-white">BRC20</div>

          {/* Loading State */}
          {loading ? (
            <div className="text-white">Loading BRC20 data...</div>
          ) : brc20list.length > 0 ? (
            <table className="min-w-full table-auto bg-gradient-to-r from-gray-1000 to-gray-700 rounded-lg overflow-hidden">
              <thead>
                <tr className="text-left text-white">
                  <th className="p-2 sm:p-4">Ticker</th>
                  <th className="p-2 sm:p-4">Holders Count</th>
                  <th className="p-2 sm:p-4">Inscription ID</th>
                </tr>
              </thead>
              <tbody>
                {brc20list.map((item, index) => (
                  <tr
                    key={index}
                    className={`${
                      index % 2 === 0
                        ? 'bg-black bg-opacity-40'
                        : 'bg-black bg-opacity-20'
                    } hover:bg-gray-600 transition-colors`}
                  >
                    <td className="p-1 sm:p-2 text-white">{item.ticker}</td>
                    <td className="p-1 sm:p-2 text-white">{item.holdersCount}</td>
                    <td className="p-1 sm:p-2 text-white">
                      {shortenInscriptionId(item.inscriptionId)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="text-white">No BRC20 data available</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FractalBRC20Page;
