import React from 'react';
import { useNavigate } from 'react-router-dom';

const BitcoinMainPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center p-8 h-screen bg-black bg-opacity-80 rounded-lg shadow-xl border border-gray-600">
      <h1 className="animated-header-orange text-5xl font-bold text-black mb-8">Bitcoin</h1>

      {/* Grid Layout for the navigation squares */}
      <div className="grid grid-cols-2 gap-8 w-full max-w-4xl">
        {/* Navigation Square for BRC20 */}
        <div
          onClick={() => navigate('/bitcoin/brc20')}
          className="nav-square-orange text-white p-8 rounded-lg shadow-lg border-gradient bg-opacity-70 hover:scale-105 transition-transform cursor-pointer"
        >
          <h2 className="text-2xl font-bold">BRC20</h2>
          <p className="text-lg">Explore BRC20 Tokens</p>
        </div>



        {/* Navigation Square for Ordinals */}
        <div
          onClick={() => navigate('/bitcoin/ordinals')}
          className="nav-square-orange text-white p-8 rounded-lg shadow-lg border-gradient bg-opacity-70 hover:scale-105 transition-transform cursor-pointer"
        >
          <h2 className="text-2xl font-bold">Ordinals</h2>
          <p className="text-lg">Explore Ordinals</p>
        </div>

        {/* Navigation Square for Runes */}
        <div
          onClick={() => navigate('/bitcoin/runes')}
          className="nav-square-orange text-white p-8 rounded-lg shadow-lg border-gradient bg-opacity-70 hover:scale-105 transition-transform cursor-pointer"
        >
          <h2 className="text-2xl font-bold">Runes</h2>
          <p className="text-lg">Explore Runes</p>
        </div>
      </div>
    </div>
  );
};

export default BitcoinMainPage;
