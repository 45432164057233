import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import BitcoinMainPage from '../components/Bitcoin/BitcoinMainPage';
import FractalMainPage from '../components/Fractal/FractalMainPage';
import CAT20Page from '../components/Fractal/CAT20Page';
import BitcoinBRC20Page from '../components/Bitcoin/BRC20Page';
import FractalBRC20Page from '../components/Fractal/BRC20Page';
import BitcoinRunesPage from '../components/Bitcoin/RunesPage';
import HoldersPage from '../components/HoldersPage';
import SolMainPage from '../components/Solana/SolMainPage';
import DiscordMainPage from '../components/Discord/DiscordMainPage';
import ProfileMainPage from '../components/Profile/ProfileMainPage';

interface MainRoutesProps {
  connected: boolean;  // Add this prop to pass down to FractalBRC20Page
  address: string | null;
  isHolder: boolean;
}

const MainRoutes: React.FC<MainRoutesProps> = ({ connected, address, isHolder }) => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/fractal" />} /> {/* Default route */}
      <Route path="/fractal/cat20" element={<CAT20Page connected={connected} />} />
      <Route path="/fractal" element={<FractalMainPage />} />
      <Route path="/bitcoin" element={<BitcoinMainPage />} />
      <Route path="/bitcoin/brc20" element={<BitcoinBRC20Page />} />
      <Route path="/fractal/brc20" element={<FractalBRC20Page connected={connected} />} />
      <Route path="/bitcoin/runes" element={<BitcoinRunesPage />} />
      <Route path="/holders" element={<HoldersPage />} />
      <Route path="/sol" element={<SolMainPage connected={connected} isHolder={isHolder} />} />
      <Route path="/profile" element={<ProfileMainPage walletAddress={address}  connected={connected}  />} />
      <Route path="/discord" element={<DiscordMainPage />} />
     </Routes>
  );
};

export default MainRoutes;
