import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';  // Import BrowserRouter
import { collectionArray } from "./components/fractalBRC";

const Index = () => {
  const [gasInfo, setGasInfo] = useState({ gas: 0 });
  const [connected, setConnected] = useState(false);
  const [address, setAddress] = useState<string | null>(null);
  const [isHolder, setIsHolder] = useState(false); // State for holder verification

  // Function to fetch gas information (e.g., sat/vb)
  const fetchGasInfo = async () => {
    try {
      const response = await fetch('https://mempool.fractalbitcoin.io/api/v1/mining/blocks/fee-rates/1m');
      const data = await response.json();
      if (data && data.length > 0) {
        const lastRow = data[data.length - 1];
        setGasInfo({ gas: lastRow.avgFee_0 });
      }
    } catch (error) {
      console.error("Failed to fetch gas info", error);
    }
  };

  const saveUser = async (walletAddress: string) => {
    try {
      const response = await fetch('/api/saveUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ wallet: walletAddress }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to save user');
      }
  
      console.log('User saved or updated:', walletAddress);
    } catch (error) {
      console.error('Error saving/updating user:', error);
    }
  };
  
  // Function to handle wallet connect
  const handleConnect = async () => {
    const unisat = (window as any).unisat;
    try {
      const result = await unisat.requestAccounts();
      const walletAddress = result[0];
      setConnected(true);
      setAddress(walletAddress);
  
      // Call the separate function to save the user
      await saveUser(walletAddress);
  
    } catch (error) {
      console.error('Failed to connect wallet:', error);
    }
  };

  // Function to handle wallet disconnect
  const handleDisconnect = async () => {
    const unisat = (window as any).unisat;
    try {
      await unisat.disconnect();
      setConnected(false);
      setAddress(null);
      setIsHolder(false); // Reset holder status on disconnect
    } catch (error) {
      console.error("Failed to disconnect wallet", error);
    }
  };
   

  const verifyHolder = async (address: string) => {
    const apiUrl = `https://open-api-fractal.unisat.io/v1/indexer/address/${address}/inscription-data`;
    const authHeader = {
      'Authorization': 'Bearer 0b0297832e468b57ba4e6a9140d4aece759f1d89d3d877e92c2ab82d38d25ff8'
    };
    let cursor = 0;

    try {
      while (true) {
        const response = await fetch(`${apiUrl}?cursor=${cursor}&size=100`, {
          headers: authHeader,
        });
        const data = await response.json();
        const inscriptions = data.data.inscription;
        const total = data.data.total;

        // Loop through the inscriptions and check if any match the collectionArray
        const hasMatch = inscriptions.some((inscription: any) => collectionArray.includes(inscription.inscriptionId));
        if (hasMatch) {
          setIsHolder(true); // Set to true if the user is verified as a holder
          console.log("is a holder");
          break;
        } else {
          setIsHolder(false);
          console.log("is not a holder");
          
        }

        cursor += 100;
        if (cursor >= total) {
          break;
        }
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

 

  // Fetch gas info on initial load
  useEffect(() => {
    fetchGasInfo();
    const interval = setInterval(fetchGasInfo, 60000); // Refresh every 60 seconds
    return () => clearInterval(interval); // Clean up interval on unmount
  }, []);

  // Trigger holder verification when the wallet is connected
  useEffect(() => {
    if (connected && address) {
      console.log("attempting to verify holder");
      verifyHolder(address);
    }
  }, [connected, address]); // Runs when `connected` or `address` changes

  return (
    <BrowserRouter> {/* Wrap your App in BrowserRouter */}
      <App
        gasInfo={gasInfo}
        connected={connected}
        address={address}
        isHolder={isHolder} // Pass the holder state to App
        handleConnect={handleConnect}
        handleDisconnect={handleDisconnect}
      />
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>
);

reportWebVitals();
