import React, { useEffect, useState, useRef } from 'react';

interface SolTrade {
  id: number;
  token_address: string;
  buyer_address: string;
  token_symbol: string;
  sol_amount: number;
  buyer_market_cap: number;
  signals_market_cap: number;
  avg_time_held: number;
  trade_datetime: string;
  total_trades: string;
  symbol: string;
  phase_one: number;
  phase_two: number;
  phase_three: number;
  phase_four: number;
  phase_five: number;
  phase_six: number;
  current_market_cap: number;
  profit: number;
}

interface SolMainPageProps {
  connected: boolean;
  isHolder: boolean;
}

// Utility function to abbreviate an address
const abbreviateAddress = (address: string) => {
  return `${address.slice(0, 6)}...${address.slice(-6)}`;
};

// Group trades by buyer_address
const groupTradesByBuyer = (trades: SolTrade[]) => {
  return trades.reduce((groups: { [buyerAddress: string]: SolTrade[] }, trade) => {
    if (!groups[trade.buyer_address]) {
      groups[trade.buyer_address] = [];
    }
    groups[trade.buyer_address].push(trade);
    return groups;
  }, {});
};

const SolMainPage: React.FC<SolMainPageProps> = ({ connected, isHolder }) => {
  const [trades, setTrades] = useState<SolTrade[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Ref to store the previous length of trades
  const prevTradesLength = useRef(0);

  // Function to request permission for browser notifications
  const requestNotificationPermission = () => {
    if ('Notification' in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
        } else {
          console.log('Notification permission denied.');
        }
      });
    }
  };

  // Send a notification when new signals are detected
  const sendNotification = (message: string) => {
    if (Notification.permission === 'granted') {
      new Notification('New Signal Alert', { body: message });
    }
  };

  // Fetch trades from the API
  const fetchTrades = async () => {
    try {
      const response = await fetch('/api/sol_trades');
      if (!response.ok) {
        throw new Error('Failed to fetch trades');
      }
      const data = await response.json();

      // Check if there are new trades compared to the previous length
      if (prevTradesLength.current && data.length > prevTradesLength.current) {
        console.log("I discovered a new trade");
        sendNotification('New trades have been detected!');
      }

      // Update the state with new trades
      setTrades(data);

      // Update the ref with the new trades length
      prevTradesLength.current = data.length;
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('An unknown error occurred');
      }
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    // Request notification permission when the component mounts
    requestNotificationPermission();

    fetchTrades();

    // Set an interval to fetch trades every 5 seconds (5000ms)
    const interval = setInterval(fetchTrades, 3000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);


  // Group trades by buyer_address
  const groupedTrades = groupTradesByBuyer(trades);
  if (!connected || !isHolder) {
    return (
      <div className="flex flex-col items-center p-8 h-screen bg-black bg-opacity-80 shadow-xl">
        <h1 className="text-5xl font-bold text-white mb-8 text-orange">
          You are not connected / not a holder.
        </h1>
      </div>
    );
  }
  return (
    <div className="flex flex-col items-center p-8 h-screen ">

  <div className="w-full bg-gray-800 p-4 rounded-lg shadow-md flex justify-between items-center mb-8">
    <h1 className="text-3xl font-bold text-white">Sol Alpha</h1>

    <div className="flex items-center space-x-4">
      <div className="flex flex-col text-left">
        <a
          href="https://t.me/ClutchSolanaVolumeBot"
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm text-blue-400 hover:text-blue-500"
        >
          @ClutchSolanaVolumeBot
        </a>
        <a
          href="https://t.me/ClutchSolanaHolderBot"
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm text-blue-400 hover:text-blue-500"
        >
          @ClutchSolanaHolderBot
        </a>
      </div>

      <a
        href="https://sol.clutchpad.io/"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-orange-500 hover:bg-orange-600 text-white font-semibold py-2 px-4 rounded-lg transition-all"
      >
        Token Launchpad
      </a>
    </div>
  </div>



      {/* Iterate over each buyer group */}
      {Object.keys(groupedTrades).map((buyerAddress, i) => (
  <div key={buyerAddress} className="mb-1 w-3/4 max-w-screen-lg">
    <h2 className="text-xl font-bold text-white mb-1">
      Trades for: #{i + 1} {/* Adding 1 to make it 1-based instead of 0-based */}
    </h2>
          <table className="min-w-full table-auto bg-gray-1000 rounded-lg shadow-lg overflow-hidden">
            <thead>
              <tr className="text-left text-white">
                <th className="p-1">Token</th>
                <th className="p-1">H#</th>
                <th className="p-1">SOL</th>
                <th className="p-1">MCap</th>      
                <th className="p-1">30 S</th>
                <th className="p-1">1 M</th>
                <th className="p-1">3 M</th>
                <th className="p-1">5 M</th>
                <th className="p-1">10 M</th>
                <th className="p-1">20 M</th>
           
                <th className="p-1">Time</th>
              </tr>
            </thead>
            <tbody>
              {groupedTrades[buyerAddress].map((trade, index) => {
                const percentChangePhaseOne = trade.buyer_market_cap
                  ? ((trade.phase_one - trade.buyer_market_cap) / trade.buyer_market_cap) * 100
                  : 0;
                const percentChangePhaseTwo = trade.buyer_market_cap
                  ? ((trade.phase_two - trade.buyer_market_cap) / trade.buyer_market_cap) * 100
                  : 0;
                const percentChangePhaseThree = trade.buyer_market_cap
                  ? ((trade.phase_three - trade.buyer_market_cap) / trade.buyer_market_cap) * 100
                  : 0;
                const percentChangePhaseFour = trade.buyer_market_cap
                  ? ((trade.phase_four - trade.buyer_market_cap) / trade.buyer_market_cap) * 100
                  : 0;
                  const percentChangePhaseFive = trade.buyer_market_cap
                  ? ((trade.phase_five - trade.buyer_market_cap) / trade.buyer_market_cap) * 100
                  : 0;
                  const percentChangePhaseSix = trade.buyer_market_cap
                  ? ((trade.phase_six - trade.buyer_market_cap) / trade.buyer_market_cap) * 100
                  : 0;

                const getColorClass = (percentChange: number): string => {
                  return percentChange > 0
                    ? 'text-green-500'
                    : percentChange < 0
                    ? 'text-red-500'
                    : 'text-white';
                };
              
                return (
                    <tr
                    key={index}
                    className={`${
                      (new Date().getTime() - new Date(trade.trade_datetime).getTime()) / 60000 > 30
                        ? 'bg-gray-600'
                        : 'bg-green-800'
                    } text-white text-sm`}
                  >
                    <td className="p-1">
      <a className="text-green-400" target="_new" href={`https://pump.fun/${trade.token_address || ''}`}>PF</a> - 
      <a className="text-green-400" target="_new" href={`https://dexscreener.com/solana/${trade.token_address || ''}`}>DEX</a> - 
      <a className="text-green-400" target="_new" href={`https://bullx.io/terminal?chainId=1399811149&address=${trade.token_address || ''}`}>BX</a> - 
      <a className="text-green-400" target="_new" href={`https://backup.bullx.io/terminal?chainId=1399811149&address=${trade.token_address || ''}`}>BBX</a> - 
      <a className="text-green-400" target="_new" href={`https://photon-sol.tinyastro.io/en/lp/${trade.token_address || ''}`}>PHO</a> {trade.token_symbol}
    </td><td className="p-1">{trade.total_trades}</td>
    <td className="p-1">{(typeof trade.sol_amount === 'number' ? trade.sol_amount.toFixed(2) : parseFloat(trade.sol_amount || '0').toFixed(2))}</td>

                    <td className="p-1">{Math.round(trade.buyer_market_cap)}</td>
                
                    <td className={`p-1 ${getColorClass(percentChangePhaseOne)}`}>
                      {trade.buyer_market_cap && trade.phase_one !== null && (
                        <span>{percentChangePhaseOne.toFixed(2)}%</span>
                      )}
                    </td>
                    <td className={`p-1 ${getColorClass(percentChangePhaseTwo)}`}>
                      {trade.buyer_market_cap && trade.phase_two !== null && (
                        <span>{percentChangePhaseTwo.toFixed(2)}%</span>
                      )}
                    </td>
                    <td className={`p-1 ${getColorClass(percentChangePhaseThree)}`}>
                      {trade.buyer_market_cap && trade.phase_three !== null && (
                        <span>{percentChangePhaseThree.toFixed(2)}%</span>
                      )}
                    </td>
                    <td className={`p-1 ${getColorClass(percentChangePhaseFour)}`}>
                      {trade.buyer_market_cap && trade.phase_four !== null && (
                        <span>{percentChangePhaseFour.toFixed(2)}%</span>
                      )}
                    </td>
                    <td className={`p-1 ${getColorClass(percentChangePhaseFive)}`}>
                      {trade.buyer_market_cap && trade.phase_five !== null && (
                        <span>{percentChangePhaseFive.toFixed(2)}%</span>
                      )}
                    </td> <td className={`p-1 ${getColorClass(percentChangePhaseSix)}`}>
                      {trade.buyer_market_cap && trade.phase_six !== null && (
                        <span>{percentChangePhaseSix.toFixed(2)}%</span>
                      )}
                    </td>
                 
           
                    <td className="p-1">
  {Math.floor((new Date().getTime() - new Date(trade.trade_datetime).getTime()) / 60000)} m
</td>

                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default SolMainPage;
