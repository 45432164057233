import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import MainRoutes from './routes/MainRoutes';
import { Button } from 'antd';
import { UserOutlined } from '@ant-design/icons'; // Use Ant Design icon for the holder status
import dragonHead from './dragon-head.svg'; 

interface AppProps {
  gasInfo: { gas: number };
  connected: boolean;
  address: string | null;
  isHolder: boolean; // Add this prop for holder verification
  handleConnect: () => void;
  handleDisconnect: () => void;
}

const App: React.FC<AppProps> = ({ gasInfo, connected, address, isHolder, handleConnect, handleDisconnect }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Check if the current route is Bitcoin or Fractal
  const isBitcoin = location.pathname.startsWith('/bitcoin');
  const isFractal = location.pathname.startsWith('/fractal');
  const isSol = location.pathname.startsWith('/sol');

  // Function to abbreviate wallet address
  const abbreviateAddress = (address: string) => {
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  };
  return (
    <div className="App">
      {/* Main header with logo and wallet info */}
      <header className="sticky top-0 z-10 bg-black p-2 shadow-lg">
        <div className="flex justify-between items-center w-full">
          {/* Left side: Logo and Holder icon */}
          <div className="flex items-center space-x-1">
            <div className="text-3xl font-bold text-left twhite">
               
                <img src="https://www.ancientsalpha.com/alogo.png" alt="Logo" className="w-20 h-auto" onClick={() => navigate('/')} />
              
            </div>
  
            {isHolder && (
              <div className="dragon-icon ml-1">
                <img src={dragonHead} alt="Dragon Holder" className="dragon-head" onClick={() => navigate('/holders')} />
              </div>
            )}
          </div>
  
          {/* Right side: sat/vb display and Wallet buttons */}
          <div className="flex items-center space-x-2">
              {/* Profile button */}
              <button
              className="profile-button text-white text-sm bg-gray-700 p-1 rounded hover:bg-gray-600"
              onClick={() => navigate('/profile')}
            >
              <UserOutlined /> {/* This renders the profile icon */}
            </button>
            <span className="wallet-button text-white text-sm">{gasInfo.gas} s/vb</span>
  
            {/* Wallet connect/disconnect button */}
            {connected ? (
              <Button className="wallet-button bg-green-500 text-white text-sm" onClick={handleDisconnect}>
                {abbreviateAddress(address!)}
              </Button>
            ) : (
              <Button className="wallet-button bg-blue-500 text-white text-sm" onClick={handleConnect}>
                Connect
              </Button>
            )}
          </div>
        </div>
  
        {/* Secondary header: Bitcoin/Fractal selection and subpage links */}
        <div className="mt-3 shadow-sm second-header">
          <div className="flex items-center space-x-2">
            {/* Buttons to toggle between Bitcoin and Fractal */}
            <button
              className={`custom-button ${isFractal ? 'active-button' : ''} text-sm p-1`}
              onClick={() => navigate('/fractal')}
            >
              F-BTC
            </button>
            <button
              className={`custom-button-orange ${isBitcoin ? 'active-button-orange' : ''} text-sm p-1`}
              onClick={() => navigate('/bitcoin')}
            >
              BTC
            </button>
            <button
              className={`custom-button-green ${isSol ? 'active-button-green' : ''} text-sm p-1`}
              onClick={() => navigate('/sol')}
            >
              SOL
            </button>
            {/* Subpage Links */}
            {isBitcoin && (
              <div className="flex space-x-2">
                <button className="text-blue hover:text-blue-400 transition-colors text-sm p-1" onClick={() => navigate('/bitcoin/brc20')}>BRC20</button>
                <button className="text-blue hover:text-blue-400 transition-colors text-sm p-1" onClick={() => navigate('/bitcoin/runes')}>Runes</button>
              </div>
            )}
  
            {isFractal && (
              <div className="flex space-x-2">
                <button className="text-blue hover:text-blue-400 transition-colors text-sm p-1" onClick={() => navigate('/fractal/brc20')}>BRC20</button>
                <button className="text-blue hover:text-blue-400 transition-colors text-sm p-1" onClick={() => navigate('/fractal/cat20')}>CAT20</button>
                <button className="text-blue hover:text-blue-400 transition-colors text-sm p-1" onClick={() => navigate('/fractal/runes')}>Runes</button>
                <button className="text-blue hover:text-blue-400 transition-colors text-sm p-1" onClick={() => navigate('/fractal/ordinals')}>Ordinals</button>
              </div>
            )}
          </div>
        </div>
      </header>
  
      {/* Render the routed pages */}
      <MainRoutes connected={connected} address={address} isHolder={isHolder} />
    </div>
  );
  
};

export default App;
