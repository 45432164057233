import React from 'react';

const HoldersPage = () => {
    return (
        <div className="flex flex-col items-center  p-8 h-screen bg-black bg-opacity-80  shadow-xl">
          <h1 className=" text-5xl font-bold text-white mb-8 text-orange">Holders Only</h1>
    
          
        </div>
      );
};

export default HoldersPage;