import React, { useEffect, useState } from 'react';

interface UserProfile {
  profile_name: string;
  email: string;
  twitter_username: string;
  discord_id?: string; // Add discord_id field
}

interface ProfileMainPageProps {
  walletAddress: string | null;
  connected: boolean;
}

const ProfileMainPage: React.FC<ProfileMainPageProps> = ({ walletAddress, connected }) => {
  const [profileData, setProfileData] = useState<UserProfile>({
    profile_name: '',
    email: '',
    twitter_username: '',
    discord_id: '',
  });

  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchProfileData = async (walletAddress: string | null) => {
    try {
      const response = await fetch(`/api/profile?wallet=${walletAddress}`);
      if (!response.ok) {
        throw new Error('Error fetching profile data');
      }
      const data = await response.json();
      setProfileData(data);
      setDataLoaded(true);
    } catch (error) {
      setError('Failed to load profile data');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const saveProfileData = async () => {
    const { profile_name, email, twitter_username } = profileData;
    if (!profile_name && !email && !twitter_username) {
      alert('Please fill in at least one field to update your profile');
      return;
    }
    try {
      const response = await fetch(`/api/profile?wallet=${walletAddress}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          profile_name: profile_name || '',
          email: email || '',
          twitter_username: twitter_username || '',
          wallet: walletAddress, // Add walletAddress to the payload
        }),
      });
      if (!response.ok) {
        throw new Error('Error saving profile data');
      }
      alert('Profile updated successfully');
    } catch (error) {
      setError('Failed to save profile data');
      console.error(error);
    }
  };

  const handleDiscordAuth = () => {
    if (walletAddress) {
      // Redirect to Discord OAuth route with wallet address
      window.location.href = `/api/auth/discord?wallet=${walletAddress}`;
    } else {
      alert('Wallet address is required');
    }
  };

  useEffect(() => {
    if (walletAddress && !dataLoaded) {
      fetchProfileData(walletAddress);
    }
  }, [walletAddress, dataLoaded]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProfileData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }
  return (
    <div className="profile-page flex flex-col items-center justify-center p-8 h-screen bg-black bg-opacity-80 shadow-xl">
      <div className="w-full max-w-lg bg-gray-900 bg-opacity-80 p-8 rounded-lg shadow-lg">
        <h2 className="text-white text-3xl font-semibold mb-6 text-center">My Profile</h2>
        <form className="w-full">
  
          {/* Conditional Rendering for Discord */}
          {profileData.discord_id ? (
            <div className="form-group mb-4">
              <label className="block text-gray-400 mb-2">Discord ID</label>
              <input
                type="text"
                name="discord_id"
                value={profileData.discord_id}
                disabled
                className="w-full p-3 text-gray-300 bg-transparent border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-red-600"
              />
            </div>
          ) : (
            <div className="form-group mb-4">
              <button
                type="button"
                onClick={handleDiscordAuth}
                className="w-full py-3 bg-blue-600 hover:bg-blue-700 text-white rounded-lg shadow transition duration-300"
              >
                Connect Discord
              </button>
            </div>
          )}
  
          {/* Profile Name Input */}
          <div className="form-group mb-4">
            <label className="block text-gray-400 mb-2">Profile Name</label>
            <input
              type="text"
              name="profile_name"
              value={profileData.profile_name}
              onChange={handleChange}
              placeholder="Enter profile name"
              className="w-full p-3 text-gray-300 bg-transparent border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-red-600"
            />
          </div>
  
          {/* Email Input */}
          <div className="form-group mb-4">
            <label className="block text-gray-400 mb-2">Email</label>
            <input
              type="email"
              name="email"
              value={profileData.email}
              onChange={handleChange}
              placeholder="Enter email"
              className="w-full p-3 text-gray-300 bg-transparent border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-red-600"
            />
          </div>
  
          {/* Twitter Username Input */}
          <div className="form-group mb-4">
            <label className="block text-gray-400 mb-2">Twitter Username</label>
            <input
              type="text"
              name="twitter_username"
              value={profileData.twitter_username}
              onChange={handleChange}
              placeholder="Enter Twitter username"
              className="w-full p-3 text-gray-300 bg-transparent border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-red-600"
            />
          </div>
  
          {/* Save Button */}
          <div className="flex justify-center">
            <button
              type="button"
              onClick={saveProfileData}
              className="w-full py-3 bg-red-600 hover:bg-red-700 text-white rounded-lg shadow transition duration-300"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
  
};

export default ProfileMainPage;
